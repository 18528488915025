import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import { t, textWithLocale } from '../../../common/i18n'
import route from '../../utils/route'
import styles from './promocion.module.scss'
import Title from '../../components/Title'
import ButtonLink from '../../components/buttons/ButtonLink'
import websiteSuffix from '../../utils/websiteSuffix'

const PageTemplate = ({ data, pageContext }) => {
  const { id_site_promocion } = pageContext
  const vehiculo = data.promociones.familia_vehiculo
  const promocion = data.promociones.site_promocions.find(
    (promo) => promo.id_site_promocion === id_site_promocion
  )
  return (
    <Layout
      selector={false}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.promociones'),
          link: route('promociones.vehiculo_coche'),
        },
        {
          display: data.promociones[textWithLocale('familia_vehiculo')],
          link: route(`promociones.vehiculo_${vehiculo}`),
        },
        { display: promocion[textWithLocale('descripcion')] },
      ]}
      seoData={data.seoData}>
      <div className={`${styles.promotions_page_background}`}>
        <Title text={promocion[textWithLocale('descripcion')]} />
        <div className={styles.single_promotion_box}>
          <div className={styles.single_promotion_image_container}>
            <img
              className={styles.single_promotion_image}
              src={promocion[textWithLocale('urlImage')]}
              alt={`${promocion[
                textWithLocale('descripcion')
              ].toLowerCase()} - ${websiteSuffix()}`}
              width="1100"
              height="400"
            />
          </div>
          <div className={styles.right_block}>
            <div className={styles.single_promotion_texts}>
              <div className={styles.single_promotion_title}>
                <span>{promocion[textWithLocale('descripcion')]}</span>
              </div>
              <div className={styles.single_promotion_summary}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: promocion[textWithLocale('texto')],
                  }}></div>
              </div>
              <div className={styles.ctas}>
                {promocion[textWithLocale('boton_texto')] ? (
                  <ButtonLink
                    withAnchor
                    to={promocion[textWithLocale('boton_url')]}>
                    {promocion[textWithLocale('boton_texto')].toUpperCase()}
                  </ButtonLink>
                ) : null}
                {promocion[textWithLocale('boton2_texto')] ? (
                  <ButtonLink
                    withAnchor
                    to={promocion[textWithLocale('boton2_url')]}>
                    {promocion[textWithLocale('boton2_texto')].toUpperCase()}
                  </ButtonLink>
                ) : null}
              </div>
            </div>
          </div>
          <div className={styles.single_promotion_terms}>
            <div
              dangerouslySetInnerHTML={{
                __html: promocion[textWithLocale('condiciones')],
              }}></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query promocionPageQuery($id_site_familia_vehiculo: Int!, $url: String!) {
    promociones(id_site_familia_vehiculo: { eq: $id_site_familia_vehiculo }) {
      familia_vehiculo
      familia_vehiculo_ca
      familia_vehiculo_es
      site_promocions {
        urlImage_ca
        urlImage_es
        urlImage_pt
        texto_ca
        texto_es
        texto_pt
        id_site_promocion
        descripcion_ca
        descripcion_es
        descripcion_pt
        fecha_fin
        final_validez_aplicable
        fecha_inicio
        resumen_ca
        resumen_es
        condiciones_ca
        condiciones_pt
        condiciones_es
        boton_texto_es
        boton_texto_ca
        boton_texto_pt
        boton_url_es
        boton_url_pt
        boton_url_ca
        boton2_texto_es
        boton2_texto_ca
        boton2_texto_pt
        boton2_url_es
        boton2_url_ca
        boton2_url_pt
        seo_url_ca
        seo_url_es
        seo_url_pt
      }
    }
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
