const websiteSuffix = () => {
  switch (process.env.GATSBY_WEB) {
    case 'rodi':
      return 'rodi'
    case 'elpaso':
      return 'el paso2000'
    case 'rodimotor':
      return 'rodi motor covipneus'
  }
  throw new Error(`Unknown website ${process.env.GATSBY_WEB}`)
}
export default websiteSuffix
